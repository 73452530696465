<template>
	<div class="record-rule clearfix">
		<div class="badge-content" v-if="recordList.length"></div>
		<div class="record-btn pull-left" @click="handleRecord"></div>
		<div class="rule-btn pull-left" @click="handleRule"></div>
		<mask-dialog :show="maskRecordShow">
			<div class="record-list">
				<img
					class="record-list-img"
					:src="require('@imgs/new_year/bg_record.png')"
					alt=""
				/>
				<!-- @touchend.stop="handleCloseRecord" -->
				<img
					class="record-list-close"
					:src="require('@imgs/new_year/btn_close.png')"
					alt=""
					@click="handleCloseRecord"
				/>
				<div class="record-list-content">
					<div class="record-list-h clearfix">
						<div class="pull-left record-list-item-t textcenter">
							时间
						</div>
						<div class="pull-left record-list-item-j textcenter">
							获得奖励
						</div>
						<div class="pull-left record-list-item-o textcenter">
							操作
						</div>
					</div>
					<div
						class="clearfix record-list-data"
						v-for="(item, index) in recordList"
						:key="index"
					>
						<div class="pull-left record-list-item-t textcenter">
							{{
								formatDate(
									item.created * 1000,
									'MM-dd hh:mm:ss'
								)
							}}
						</div>
						<!-- <div class="pull-left record-list-item-j textcenter">
							{{ item.rewardInfo }}
						</div> -->
						<div class="pull-left record-list-item-j textcenter">
							{{
								item.rewardTp == 6
									? `获得${item.integral}幸运值`
									: item.rewardInfo
							}}
						</div>
						<div class="pull-left record-list-item-o textcenter">
							<!-- @touchend.stop="handleGet" -->
							<img
								v-if="item.rewardTp == 1"
								class="receive-btn"
								:src="
									require('@imgs/new_year/btn_getsmall.png')
								"
								alt=""
								@click="handleGet(item)"
							/>
							<span v-if="item.rewardTp == 6" class="receive-info"
								>已领取</span
							>
						</div>
					</div>
				</div>
			</div>
		</mask-dialog>
		<mask-dialog :show="maskRuleShow">
			<div class="rule">
				<!-- @touchend.stop="handleCloseRule" -->
				<img
					class="rule-close"
					:src="require('@imgs/new_year/btn_close.png')"
					alt=""
					@click.stop="handleCloseRule"
				/>
				<img
					class="rule-bg"
					:src="require('@imgs/new_year/bg_rule.png')"
					alt=""
				/>
				<div class="rule-content">
					<p>
						. 活动期间, 每日都可获得免抽奖费次数, 最高可获得3次免费次数;
					</p>
					<p>
						.
						在疯狂游乐场游戏中心任意一款游戏充值任意金额(不包括海盗来了,
						呆呆打僵尸), 可额外获得一次抽奖机会;
					</p>
					<p>
						. 充值赠送抽奖次数最高不超过5次,
						每天0点重置(当天获得的次数只能当天使用);
					</p>
					<p>. 本活动仅针对疯狂游乐场平台用户开放;</p>
					<p>. 活动最终解释权归疯狂游乐场官方运营团队所有.</p>
				</div>
			</div>
		</mask-dialog>
		<lottery-result
			:maskShow="maskShow"
			@close="handleClose"
			:luckInfo="luckInfo"
			:isGameGift="isGameGift"
			:isPhoneSuccess="isPhoneSuccess"
			:gameGifts="gameGifts"
		></lottery-result>
	</div>
</template>

<script>
import MaskDialog from '@components/maskDialog';
import $api from '@api/index';
import { formatDate } from '@utils/lib';
import LotteryResult from './lottery_result';
import bus from '@utils/bus.js';

export default {
	data() {
		return {
			maskShow: false,
			maskRecordShow: false,
			maskRuleShow: false,
			formatDate,
			recordList: [],
			gameGifts: [],
			isGameGift: false,
			luckInfo: {},
			isPhoneSuccess: false,
		};
	},

	components: { MaskDialog, LotteryResult },

	methods: {
		async getGiftList() {
			const params = { sort: this.luckInfo.sort };
			const res = await $api.newyear_api.toGetGiftList(params);

			this.gameGifts = res.data || [];
		},
		handleGet(data) {
			this.luckInfo = data;

			const { giftCode, gameName } = data;

			if (giftCode) {
				// 已领取: 游戏礼包 || 手机卡
				if (gameName) {
					// 游戏礼包
					bus.$emit('gift-success', true);
				} else {
					this.isPhoneSuccess = true;
				}
			} else {
				// 游戏礼包, 未领取
				this.isGameGift = true;
				this.getGiftList();
			}
			this.maskShow = true;
		},
		handleClose() {
			this.maskShow = false;
			this.isGameGift = false;
			this.isPhoneSuccess = false;
			bus.$emit('gift-success', false);
		},
		handleRecord() {
			this.maskRecordShow = true;
			this.getLuckReward();
		},
		handleRule() {
			this.maskRuleShow = true;
		},
		handleCloseRecord(event) {
			this.maskRecordShow = false;
		},
		handleCloseRule() {
			this.maskRuleShow = false;
		},
		async getLuckReward() {
			const res = await $api.newyear_api.toGetLuckReward();

			this.recordList = res.data || [];
		},
	},

	created() {
		bus.$on('record-list', this.getLuckReward);
	},
};
</script>

<style scoped lang="scss">
.record-rule {
	position: relative;
	.badge-content {
		position: absolute;
		top: 0;
		right: rem(190);
		background-color: #f56c6c;
		border-radius: 50%;
		color: #fff;
		display: inline-block;
		width: 10px;
		height: 10px;
		text-align: center;
		white-space: nowrap;
		border: 1px solid #fff;
	}
	.record-btn {
		width: rem(170);
		height: rem(50);
		background: url('~@imgs/new_year/btn_prizerecord.png');
		background-repeat: no-repeat;
		background-size: cover;
	}
	.rule-btn {
		width: rem(170);
		height: rem(50);
		margin-left: rem(20);
		background: url('~@imgs/new_year/btn_rule.png');
		background-repeat: no-repeat;
		background-size: cover;
	}
	.rule {
		width: rem(550);
		height: rem(600);
		position: relative;
		.rule-close {
			width: rem(60);
			height: rem(60);
			position: absolute;
			top: 18px;
			right: -12px;
			z-index: 10000;
		}
		.rule-bg {
			width: 100%;
			height: 100%;
		}
		.rule-content {
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 rem(18);
			margin-top: rem(95);
			color: #791f1d;
			p {
				font-size: rem(22);
			}
		}
	}
	.record-list {
		width: rem(550);
		height: rem(520);
		position: relative;
		.record-list-img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.record-list-close {
			width: rem(60);
			height: rem(60);
			position: absolute;
			top: rem(38);
			right: -14px;
			z-index: 10000;
		}
		.record-list-content {
			margin: 0 rem(8);
			height: rem(435);
			background: transparent;
			position: absolute;
			margin-top: rem(70);
			overflow-y: scroll;
			overflow-x: hidden;
			text-align: center;
			.record-list-h {
				width: rem(535);
				font-size: rem(28);
				background: #c45b46;
				color: #ffeedd;
				font-weight: bold;
			}
			.record-list-item-t {
				width: rem(210);
				line-height: rem(85);
				// background: turquoise;
			}
			.record-list-item-j {
				width: rem(180);
				line-height: rem(85);
				// background: violet;
			}
			.record-list-item-o {
				width: rem(143);
				line-height: rem(85);
				// background: yellow;
			}
			.record-list-data {
				font-size: rem(20);
				border-bottom: 1px dashed #f49777;
				height: rem(85);
				font-weight: 600;
			}
			.receive-btn {
				width: rem(130);
				height: rem(56);
				display: inline-block;
				vertical-align: middle;
			}
			.receive-info {
				font-size: rem(28);
				font-weight: 700;
				color: #c45b46;
			}
		}
	}
}
</style>
