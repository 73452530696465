<template>
	<div class="luck-draw clearfix square">
		<div class="luck-draw-count" v-if="luckList.length">
			剩余次数: {{ luckCount }}次
		</div>
		<ul class="square-inner flex" v-if="luckList.length">
			<li
				v-for="(item, index) in showLuckList"
				:key="index"
				@click="handleLottery(item)"
			>
				<img
					class="luck-draw-item-border"
					:src="require('@imgs/new_year/img_choose.png')"
					alt=""
					v-if="activeId == item.sort"
				/>
				<img
					class="luck-draw-item-img"
					:class="
						activeId == item.sort
							? 'luck-draw-item-img-active'
							: 'luck-draw-item-img-notactive'
					"
					:src="item.img"
					alt=""
				/>
			</li>
		</ul>
		<lottery-result
			:maskShow="maskShow"
			@close="handleClose"
			:luckInfo="luckInfo"
			:isCount="isCount"
			:isIntegral="isIntegral"
			:isSubscribe="isSubscribe"
			:isPhoneCard="isPhoneCard"
			:isGameGift="isGameGift"
			:payCount="payCount"
			:gameGifts="gameGifts"
		></lottery-result>
	</div>
</template>

<script>
import LuckDraw from '@modules/luck_draw.js';
import $api from '@api/index';
import LotteryResult from './lottery_result';
import bus from '@utils/bus.js';

export default {
	data() {
		return {
			isCount: false,
			isIntegral: false,
			isPhoneCard: false,
			isGameGift: false,
			gameGifts: [],
			countMaskShow: false,
			maskShow: false,
			luckCount: 0,
			activeId: '',
			isCanLuck: true,
			luckList: [],
			luckInfo: {},
			isSubscribe: 0,
			payCount: 0,
		};
	},

	components: { LotteryResult },

	computed: {
		showLuckList: function() {
			let tempLuckList = JSON.parse(JSON.stringify(this.luckList));
			tempLuckList.splice(4, 0, {
				sort: 'btn_luckdraw',
				img:
					'https://gc-oss.hortorgames.com/201219/163705/btn_luckdraw.7.png',
			});
			return tempLuckList;
		},
	},

	methods: {
		handleClose() {
			this.isCount = false;
			this.maskShow = false;
			this.isIntegral = false;
			this.isPhoneCard = false;
			this.isGameGift = false;
			bus.$emit('gift-success', false);
		},
		async getGiftList() {
			const params = { sort: this.luckInfo.sort };
			const res = await $api.newyear_api.toGetGiftList(params);

			this.gameGifts = res.data || [];
		},
		async luckRoll() {
			const res = await $api.newyear_api.toLuckRoll();
			console.log('--- res ----', res);

			this.luckInfo = res.data || {};
			this.luckInfo.id = res.id || '';

			const tp = this.luckInfo.tp;
			const giftResp = res.giftResp;

			// tp == 6 积分
			if (tp == 6) {
				// 积分
				this.isIntegral = true;
				return;
			}
			if (tp == 1 && giftResp) {
				// 电话卡
				this.isPhoneCard = true;
			} else {
				// 游戏礼包
				this.getGiftList();
				this.isGameGift = true;
			}
		},
		async handleLottery(data) {
			if (data.sort != 'btn_luckdraw') return;

			if (new Date().getTime() > bus.endAt * 1000) {
				this.$toast.top('活动已结束!');
				return;
			}

			if (!this.isCanLuck) return;

			if (this.luckCount < 1) {
				this.isCount = true;
				this.maskShow = true;
				return;
			}

			this.isCanLuck = false;

			await this.luckRoll();

			const { sort } = this.luckInfo;
			this.luckCount -= 1;
			this.luckDrawFn.run(
				sort, // 中奖id
				(params) => {
					// 停留在当前格子的回调函数
					// 拿到当前 active 状态的 id
					this.activeId = params.id;
				},
				(params) => {
					// 最终停止的回调函数
					// 最后转盘停止的地方，可以弹出奖励弹框或其他操作
					this.activeId = params.id;
					bus.$emit('reload-data');

					setTimeout(() => {
						this.isCanLuck = true;
						this.maskShow = true;
					}, 800);
				}
			);
		},
		async getLuckInfo() {
			const res = await $api.newyear_api.toGetLuckInfo();
			console.log('=== res ===', res);
			const data = res.data || {};
			const turnTableInfos = (res.data && res.data.turnTableInfos) || [];

			this.luckList = turnTableInfos;
			bus.hasWinnerInfo = res.hasWinnerInfo || false;
			bus.endAt = data.endAt || 0;
			bus.$emit('update-time', {
				openAt: data.openAt || 0,
				endAt: data.endAt || 0,
			});
		},
		async getLuckCount() {
			if (new Date().getTime() > bus.endAt * 1000) {
				return;
			}
			const res = await $api.newyear_api.toGetLuckCount();
			console.log('=== count ===', res);
			this.luckCount = res.data || 0;
			// this.luckCount = 0;
			if (typeof res.isSubscribe == 'number') {
				this.isSubscribe = res.isSubscribe;
			}
			if (typeof res.payCount == 'number') {
				this.payCount = res.payCount;
			}
		},
		initData() {
			const prizeList = this.luckList.map((item) => ({ id: item.sort }));
			// 旋转规则数组
			const rotateDir = [
				{ index: 0, next: 1 },
				{ index: 1, next: 2 },
				{ index: 2, next: 4 },
				{ index: 4, next: 7 },
				{ index: 7, next: 6 },
				{ index: 6, next: 5 },
				{ index: 5, next: 3 },
				{ index: 3, next: 0 },
			];
			this.luckDrawFn = new LuckDraw(prizeList, rotateDir, 3, 8);
		},
	},

	async created() {
		for (let i = 0; i < 20; i++) {
			this.gameGifts.push({
				gameId: `tnlp${i}`,
				gameName: `头脑轮盘${i}`,
				gameIcon:
					'https://wxmini-resource.hortorgames.com/background-image/jfq3-1612251318.png',
				batch: 2021012902,
				active: false,
			});
		}

		await this.getLuckInfo();
		this.getLuckCount();
		this.initData();

		bus.$on('luck-count', this.getLuckCount);
	},
};
</script>

<style scoped lang="scss">
img {
	-webkit-touch-callout: none;
}
.luck-draw {
	text-align: center;
	position: relative;
	.luck-draw-count {
		position: absolute;
		width: rem(140);
		height: rem(39);
		line-height: rem(39);
		background: #4c0d0a;
		font-size: rem(20);
		font-weight: 800;
		opacity: 0.56;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		z-index: 10000;
		border-radius: rem(39/2);
		border: 2px solid #efcb63;
		margin-top: rem(50);
	}
	.luck-draw-item-border {
		width: 100%;
		height: 100%;
	}
	.luck-draw-item-img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.luck-draw-item-img-active {
		width: rem(135);
		height: rem(135);
	}
	.luck-draw-item-img-notactive {
		width: 100%;
		height: 100%;
	}
}

.square {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 100%; /* padding百分比是相对父元素宽度计算的 */
	// margin-bottom: 30px;
}
.square-inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; /* 铺满父元素容器，这时候宽高就始终相等了 */
}
.square-inner > li {
	position: relative;
	width: calc(98% / 3); /* calc里面的运算符两边要空格 */
	height: calc(98% / 3);
	margin-right: 1%;
	margin-bottom: 1%;
	overflow: hidden;
}
.flex {
	display: flex;
	flex-wrap: wrap;
}
.flex > li {
	flex-grow: 1; /* 子元素按1/n的比例进行拉伸 */
	// background-color: #4d839c;
	text-align: center;
	color: #fff;
	font-size: 50px;
	line-height: 2;
}
.flex > li:nth-of-type(3n) {
	/* 选择个数是3的倍数的元素 */
	margin-right: 0;
}
.flex > li:nth-of-type(n + 7) {
	/* 选择倒数的三个元素，n可以取0 */
	margin-bottom: 0;
}
</style>
