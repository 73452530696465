<template>
	<div class="christmas-index clearfix">
		<span class="version" v-if="$root.env != 'prod'">v1.1.6</span>
		<div class="operat-btn-box">
			<img
				:src="require('@imgs/new_year/back.png')"
				alt=""
				@click="handleBack"
			/>
			<img
				:src="require('@imgs/new_year/reload.png')"
				alt=""
				@click="handleReloadData"
			/>
		</div>
		<!-- 圣诞活动首页 -->
		<!-- 1. 活动时间 -->
		<active-time class="christmas-time"></active-time>
		<record-rule class="pull-right record-rule"></record-rule>
		<div class="box-center clearfix">
			<!-- 2. 中奖纪录, 活动规则 -->
			<!-- <record-rule class="pull-right record-rule"></record-rule> -->
			<!-- 3. 抽奖模块 -->
			<luck-draw class="christmas-luck"></luck-draw>
		</div>
		<!-- 4. 获奖信息滚动模块 -->
		<text-scroll class="text-scroll" :dataList="noticeList"></text-scroll>
		<!-- 5. 幸运值榜单 -->
		<luck-list class="luck-list"></luck-list>
		<!-- 6. 幸运值抽奖弹窗 -->
		<luck-rule class="luck-rule pull-right"></luck-rule>
		<!-- 7. 幸运值信息 -->
		<div class="luck-info">
			<luck-info></luck-info>
		</div>
		<div class="footer-title">
			本活动最终解释权归疯狂游乐场官方运营团队所有
		</div>
		<div class="footer-title-box"></div>
	</div>
</template>

<script>
import ActiveTime from './components/active_time';
import RecordRule from './components/record_rule';
import LuckDraw from './components/luck_draw';
import TextScroll from './components/text_scroll';
import LuckList from './components//luck_list';
import LuckRule from './components/luck_rule';
import LuckInfo from './components//luck_info';
import bus from '@utils/bus.js';

export default {
	data() {
		return {
			noticeList: [
				['心口难开', '疯狂幸运值50'],
				['落旳尐孩', '疯狂幸运值100'],
				['腾i', '移动话费卡100元'],
				['我心透心凉', '移动话费卡100元'],
				['女王心', '移动话费卡200元'],
				['不堪浮华', '游戏礼包50元'],
				['有福', '疯狂幸运值1000'],
				['你真逗比', '游戏礼包50元'],
				['默默的承受', '移动话费卡200元'],
				['默默的付出', '移动话费卡100元'],
				['小心翼翼', '疯狂幸运值20'],
				['安定', '疯狂幸运值50'],
				['怀念·最初', '疯狂幸运值100'],
				['相知相惜', '疯狂幸运值1000'],
				['婞褔vīp', '疯狂幸运值100'],
				['以心换心', '移动话费卡100元'],
				['演绎悲伤', '疯狂幸运值100'],
				['悉数沉淀', '移动话费卡200元'],
				['solo', '疯狂幸运值20'],
				['爱被冰凝固', '疯狂幸运值50'],
				['迷路的男人', '疯狂幸运值50'],
				['默默的爱', '疯狂幸运值1000'],
				['命运不堪浮华', '疯狂幸运值50'],
				['最迷人的危险', '游戏礼包100元'],
			],
		};
	},

	components: {
		ActiveTime,
		RecordRule,
		LuckDraw,
		TextScroll,
		LuckList,
		LuckRule,
		LuckInfo,
	},

	methods: {
		handleReloadData() {
			bus.$emit('reload-data');
			bus.$emit('luck-count');
			this.$toast.top('刷新数据成功!');
		},
		handleBack() {
			if (window.parent) {
				window.parent.postMessage({ msgType: 'back', data: {} }, '*');
			}
		},
		initData() {
			const query = this.$route.query || {};
			console.log('=== query ===', query);

			query.openId = query.openId || query.userId;
			query.time = query.time || query.loginTime;
			bus.query = query;
		},
	},

	created() {
		this.initData();
	},

	mounted() {
		this.$nextTick(() => {
			bus.$emit('record-list');
		});
	},
};
</script>

<style scoped lang="scss">
.christmas-index {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	background: url('../../../static/imgs/new_year/bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #c51e13;
	.operat-btn-box {
		position: fixed;
		top: rem(30);
		right: 0;
		z-index: 988;
		img {
			display: block;
			width: rem(100);
			margin-top: rem(10);
		}
	}
	.christmas-time {
		margin-top: rem(260);
	}
	.record-rule {
		margin-top: rem(16);
		margin-right: rem(70);
	}
	.box-center {
		margin: rem(85) rem(72);
		margin-bottom: rem(0);
	}
	.text-scroll {
		margin-top: rem(20);
	}
	.luck-list {
		margin-top: rem(0);
		// background: #ccc;
	}
	.luck-rule {
		margin-top: rem(-20);
		margin-right: rem(70);
	}
	.luck-info {
		position: relative;
	}
	.footer-title {
		text-align: center;
		width: 100%;
		color: #fac3c3;
		font-size: rem(24);
		font-weight: 700;
		margin-bottom: 10px;
	}
	.version {
		position: absolute;
		top: 0;
		left: 0;
		font-size: 15px;
	}
}
@media screen and (min-width: 540px) {
	html {
		background: red;
	}
}
</style>
