<template>
	<div class="active-time">
		<span class="active-time-content">
			{{
				openAt
					? `活动时间: ${formatDate(
							openAt * 1000,
							'MM月dd日 hh:mm'
					  )}到${formatDate(endAt * 1000, 'MM月dd日 hh:mm')}`
					: '活动时间: 12月25日00:00到12月30日24:00'
			}}
		</span>
		<img
			class="active-time-img"
			:src="require('@imgs/new_year/img_time.png')"
			alt=""
		/>
	</div>
</template>

<script>
import bus from '@utils/bus.js';
import { formatDate } from '@utils/lib';

export default {
	data() {
		return {
			bus,
			formatDate,
			openAt: '',
			endAt: '',
		};
	},

	methods: {
		updateTime({ openAt, endAt }) {
			this.openAt = openAt;
			this.endAt = endAt;
		},
	},

	created() {
		bus.$on('update-time', this.updateTime);
	},
};
</script>

<style scoped lang="scss">
.active-time {
	position: relative;
	width: 100%;
	height: rem(30);
	text-align: center;
	.active-time-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: rem(20);
		color: #fedeb1;
		font-weight: bold;
		width: 100%;
		z-index: 10;
	}
	.active-time-img {
		width: rem(470);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
</style>
