import vm from '../../main';
import device from 'current-device';

const oneDay = 24 * 60 * 60 * 1000;

/**
 * 时间戳格式化
 * @param  {[longnumber]} timestamp 要格式化的时间戳
 * @param  {[boolean]} toSecond  是否格式化到秒 否则格式化到毫秒
 * @return {[longnumber]}  返回格式化成功后的时间戳
 */
export function renderTimestamp(timestamp, toSecond) {
	if (!timestamp || typeof timestamp !== 'number') return timestamp;
	let len = timestamp.toString().length;
	if (toSecond && len === 13) {
		return Math.floor(timestamp / 1000);
	}
	if (!toSecond && len === 10) {
		return timestamp * 1000;
	}
	return timestamp;
}

/**
 * 获取时间对象
 * @param  {[longnumber]} time 时间戳
 * @return {[Date]} 返回时间对象
 */
export function newDate(time) {
	time = renderTimestamp(time);
	if (!time) return new Date();
	return new Date(time);
}

/**
 * 时间格式化
 * @param  {[number]} time 要格式化的时间戳，到毫秒
 * @param  {[string]} fmt  要格式化成的形式，默认 yyyy-MM-dd hh:mm:ss
 * @return {[string]}      返回格式化成功后的字符串
 */
export function formatDate(time, fmt) {
	if (!time || isNaN(time)) return '--';
	let date = newDate(time);
	fmt = fmt || 'yyyy-MM-dd hh:mm:ss';

	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(
			RegExp.$1,
			(date.getFullYear() + '').substr(4 - RegExp.$1.length)
		);
	}
	let o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
	};
	for (let k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			let str = o[k] + '';
			fmt = fmt.replace(
				RegExp.$1,
				RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length)
			);
		}
	}
	return fmt;
}

/**
 * 秒数格式化
 * @param  {[time]} time 要格式化的毫秒数
 * @return {[string]} 返回格式化后的文字
 */
export function formatTime(time) {
	time = renderTimestamp(time);
	let str = [];
	let ms = 1000;
	let s = 1000 * 60;
	let m = 1000 * 60 * 60;
	let d = 1000 * 60 * 60 * 24;

	let dd = parseInt(time / d, 10);
	let hh = parseInt((time % d) / m, 10);
	let mm = parseInt((time % m) / s, 10);
	let ss = parseInt((time % s) / ms, 10);

	if (dd) str.push(`${dd}天`);
	if (hh) str.push(`${hh}小时`);
	if (mm) str.push(`${mm}分钟`);
	if (ss) str.push(`${ss}秒`);

	return str.join('');
}

/**
 * 时间格式化
 * @param  {[number]} timeStr 要格式化的时间字符串 ex: 20170808
 * @return {[string]}      返回格式化成功后的字符串
 */
export function formatDateStr(timeStr) {
	if (!timeStr) return '--';
	let re = /^(\d{4})(\d{2})?(\d{2})$/;
	return timeStr.replace(re, '$1-$2-$3').replace('--', '-');
}

/**
 * 时间格式化
 * @param  {[number]} num 要判断的数字
 * @return {[string]}     返回 '--'或者原数据
 */
export function isEmptyNum(num) {
	return !num && num !== 0 ? '--' : num;
}

/**
 * 获取一天开始时间
 * @param  {[time]} 时间戳
 * @param  {[isTimestamp]} 是否返回时间戳
 * @return {[Date]}  返回时间对象或者时间戳
 */
export function getDayStart(time, isTimestamp) {
	let date = newDate(time);
	date.setHours(0);
	date.setMinutes(0);
	date.setSeconds(0);
	date.setMilliseconds(0);
	if (isTimestamp) return renderTimestamp(date.getTime(), true);
	return date;
}
/**
 * 获取一天结束时间
 * @param  {[time]} 时间戳
 * @param  {[isTimestamp]} 是否返回时间戳
 * @return {[Date]}  返回时间对象或者时间戳
 */
export function getDayEnd(time, isTimestamp) {
	let date = newDate(time);
	date.setHours(23);
	date.setMinutes(59);
	date.setSeconds(59);
	date.setMilliseconds(999);
	if (isTimestamp) return renderTimestamp(date.getTime(), true);
	return date;
}

/**
 * 获取时间区间的时间戳
 * @param  {[array]} range 时间区间数组
 * @return {[json]}  返回 start/end 时间戳对象（结束时间是第二天0点）
 */
export function getRangeTime(range) {
	if (!range || !range.length) return false;
	if (!range[0] || !range[1]) return false;
	let start = parseInt(range[0].getTime() / 1000, 10);
	let end = parseInt((range[1].getTime() + oneDay) / 1000, 10);

	return {
		start: start,
		end: end,
	};
}

/**
 * 获取到秒的时间戳
 * @param  {[date]} time 时间戳
 * @return {[json]}  返回到秒时间戳
 */
export function getTime(time) {
	return parseInt(new Date(time).getTime() / 1000, 10);
}

/**
 * 获取特定日期时间段
 * @param  {[number,string]} days 要获取的类型，
 * 默认 今日
 * 数字时 最近 days 天
 * yesterday 昨天
 * thisweek 本周
 * thismonth 本月
 * lastweek 上周
 * lastmonth 上月
 * @return {[obj]}  .start 开始时间戳；.end结束时间戳
 */
export function getDate(days) {
	let start = getDayStart();
	let end = getDayStart();
	let result = {};

	days = typeof days === 'string' ? days.toLowerCase() : days;
	if (typeof days === 'number') {
		result.start = start.getTime() - oneDay * days;
	} else if (days === 'thisweek') {
		result.start = start.getTime() - oneDay * ((start.getDay() || 7) - 1);
	} else if (days === 'thismonth') {
		result.start = start.setDate(1);
	} else if (days === 'lastweek') {
		result.end = start.getTime() - oneDay * ((start.getDay() || 7) - 1);
		result.start = result.end - oneDay * 7;
	} else if (days === 'lastmonth') {
		let thisMonthStart = start.setDate(1);
		result.start = start.setMonth(start.getMonth() - 1);
		result.end = thisMonthStart;
	} else if (days === 'yesterday') {
		result.end = start.getTime();
		result.start = result.end - oneDay;
	} else if (days === 'today') {
		result.start = start.getTime();
		result.end = result.start + oneDay;
	} else {
		result.start = start.getTime();
	}

	result.end = result.end || end.getTime();
	return result;
}

/**
 * 将数字千位分割格式化，可处理小数
 * @param  {[number]} num 要格式化的数字
 * @return {[string]}     返回格式化成功后的结果
 */
export function formatNum(num) {
	if ((!num && num !== 0) || isNaN(num - 0)) return '--';
	let strs = num.toString().split('.');
	strs[0] = strs[0].replace(/(?=(?!\b)(\d{3})+$)/g, ',');
	return strs.join('.');
}

/**
 * 将json 序列化，只支持简单对象的序列化，不支持深层序列化
 * @param  {[json]} obj 要序列化的json
 * @return {[string]}   序列化成功后的字符串
 */
export function queryString(obj) {
	if (!obj || typeof obj !== 'object') return '';
	let params = [];
	for (let i in obj) {
		params.push(i + '=' + obj[i]);
	}
	return params.join('&');
}

/**
 * 验证是否是电话号
 * @param  {[string]} str 要验证的字符串
 * @return {[boolean]}     [boolean]
 */
export function validateTelphone(str) {
	let isTelPhone = /^(\(\d{3,4}\)|\d{3,4}-)?\d{7,8}$/;
	return str && isTelPhone.test(str);
}

/**
 * 验证是否是手机号
 * @param  {[string]} str 要验证的字符串
 * @return {[boolean]}     [boolean]
 */
export function validateMobile(str) {
	if (str === 'test') {
		return true;
	} else {
		let isTelPhone = /^[0-9][0-9]\d{9}$/;
		return str && isTelPhone.test(str);
	}
}

/**
 * 对象转数组
 * @param  {[json]}   data 要转换的对象
 * @param  {[string]} key 属性名要转换成的字段
 * @return {[arry]}   转换成功的数组
 */
export function objToArry(data, key) {
	let temp = [];
	key = key || 'key';
	for (let i in data) {
		data[i][key] = i;
		temp.push(data[i]);
	}
	return temp;
}
/**
 * 数组转对象
 * @param  {[arry]}   arry 要转换的对象
 * @param  {[string]} key 属性名要转换成的字段
 * @return {[json]}   转换成功的对象
 */
export function arryToObj(arry, key) {
	let temp = {};
	key = key || 'key';
	arry.map((e) => {
		temp[e[key]] = e;
	});
	return temp;
}

/**
 * is function
 */
export function isFun(fun) {
	return fun && typeof fun === 'function';
}

/**
 * is array
 */
export function isArray(arr) {
	return Object.prototype.toString.call(arr) === '[object Array]';
}

/**
 * is obj
 */
export function isObject(obj) {
	return Object.prototype.toString.call(obj) === '[object Object]';
}

/**
 * 对象拷贝
 * @param  {[json]}  data 默认的对象
 * @return {[arry]}  合并后的对象
 */
export function each(data, fun) {
	if (isFun(fun)) {
		for (let i in data) {
			fun(i, data[i]);
		}
	}
}

/**
 * 对象拷贝
 * @param  {[json]}  data 默认的对象
 * @return {[arry]}  合并后的对象
 */
export function copy(data) {
	return JSON.parse(JSON.stringify(data));
}

/**
 * 对象拷贝
 * @param  {[json]}  data 默认的对象
 * @return {[arry]}  合并后的对象
 */
export function isNull(val) {
	return typeof val === 'undefined';
}

/**
 * 对象合并
 * @param  {[json]}  def 默认的对象
 * @param  {[json]}  data 要添加的对象
 * @param  {[boolean]} clear 是否清楚数据绑定
 * @return {[arry]}  合并后的对象
 */
export function extend(def, data, clear) {
	for (let i in data) {
		if (!isNull(data[i])) {
			def[i] = data[i];
		}
	}
	if (clear) {
		return copy(def);
	}
	return def;
}

/**
 * 对象赋值
 * @param  {[json]}  def 默认的对象
 * @param  {[json]}  data 要赋值的对象
 * @param  {[boolean]} clear 是否清楚数据绑定
 * @return {[arry]}  赋值后的对象
 */
export function assign(def, data, clear) {
	for (let i in def) {
		if (!isNull(data[i])) {
			def[i] = data[i];
		}
	}
	if (clear) {
		return copy(def);
	}
	return def;
}

/**
 * 合并签名信息
 */
export function mergeSignData(inData, root) {
	if (!root.signData) return copy(inData);
	return extend(inData, root.signData, true);
}

/*
 转成 bool
*/

export function handleBool(list, key) {
	list.map((val) => {
		val[key] = translateBool(val[key]);
	});
	return list;
}

function translateBool(val) {
	if (typeof val === 'boolean') {
		return val;
	} else if (typeof val === 'string') {
		return val === 'true';
	} else if (typeof val === 'number') {
		return !!val;
	}
}

/**
 * 简单对象数组排序
 * @param  {[obj]} list 要重新排序的对象数组
 * @param  {String}  key 排序的key值
 * @param  {Boolean} desc 顺序
 * @return {[obj]}  排序后的对象数组
 */
export function sortObjectList(list, key, desc) {
	return list.sort(keysrt(key, desc));
}

function keysrt(key, desc) {
	return function(a, b) {
		return desc ? ~~(a[key] < b[key]) : ~~(a[key] > b[key]);
	};
}

/*
处理 element-ui 需要的 time
*/

export function handleTimeStyle(data) {
	return data.split('T')[1].split('.')[0];
}

/**删除对象中指定key 返回新对象*/
export function delKeys(obj, keyArr) {
	let o = copy(obj);
	keyArr.forEach((key) => {
		delete o[key];
	});
	return o;
}
/**选取对象中指定key 返回新对象 */
export function getKeys(obj, keyArr) {
	let o = {};
	keyArr.forEach((key) => {
		o[key] = obj[key];
	});
	return o;
}

// -------------- 微信时间格式处理 start ----------------
/**
 * 对Date的扩展，将 Date 转化为指定格式的String。
 *
 *  月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
 *  年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)。
 *
 *  【示例】：
 *  common.formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss.S') ==> 2006-07-02 08:09:04.423
 *  common.formatDate(new Date(), 'yyyy-M-d h:m:s.S')      ==> 2006-7-2 8:9:4.18
 *  common.formatDate(new Date(), 'hh:mm:ss.S')            ==> 08:09:04.423
 *
 */
var _formatDate = function(date, fmt) {
	var o = {
		'M+': date.getMonth() + 1, //月份
		'd+': date.getDate(), //日
		'h+': date.getHours(), //小时
		'm+': date.getMinutes(), //分
		's+': date.getSeconds(), //秒
		'q+': Math.floor((date.getMonth() + 3) / 3), //季度
		S: date.getMilliseconds(), //毫秒
	};
	if (/(y+)/.test(fmt))
		fmt = fmt.replace(
			RegExp.$1,
			(date.getFullYear() + '').substr(4 - RegExp.$1.length)
		);
	for (var k in o)
		if (new RegExp('(' + k + ')').test(fmt))
			fmt = fmt.replace(
				RegExp.$1,
				RegExp.$1.length == 1
					? o[k]
					: ('00' + o[k]).substr(('' + o[k]).length)
			);
	return fmt;
};

/**
 * 仿照微信中的消息时间显示逻辑，将时间戳（单位：毫秒）转换为友好的显示格式.
 *
 * 1）7天之内的日期显示逻辑是：今天、昨天(-1d)、前天(-2d)、星期？（只显示总计7天之内的星期数，即<=-4d）；
 * 2）7天之外（即>7天）的逻辑：直接显示完整日期时间。
 *
 * @param  {[long]} timestamp 时间戳（单位：毫秒），形如：1550789954260
 * @param {boolean} mustIncludeTime true表示输出的格式里一定会包含“时间:分钟”
 * ，否则不包含（参考微信，不包含时分的情况，用于首页“消息”中显示时）
 *
 * @return {string} 输出格式形如：“刚刚”、“10:30”、“昨天 12:04”、“前天 20:51”、“星期二”、“2019/2/21 12:09”等形式
 * @author 即时通讯网([url=http://www.52im.net]http://www.52im.net[/url])
 * @since 1.1
 */
export function getTimeStringAutoShort2(timestamp, mustIncludeTime) {
	// 当前时间
	var currentDate = new Date();
	// 目标判断时间
	var srcDate = new Date(parseInt(timestamp, 10));

	var currentYear = currentDate.getFullYear();
	var currentMonth = currentDate.getMonth() + 1;
	var currentDateD = currentDate.getDate();

	var srcYear = srcDate.getFullYear();
	var srcMonth = srcDate.getMonth() + 1;
	var srcDateD = srcDate.getDate();

	var ret = '';

	// 要额外显示的时间分钟
	var timeExtraStr = mustIncludeTime
		? ' ' + _formatDate(srcDate, 'hh:mm')
		: '';

	// 当年
	if (currentYear == srcYear) {
		var currentTimestamp = currentDate.getTime();
		var srcTimestamp = timestamp;
		// 相差时间（单位：毫秒）
		var deltaTime = currentTimestamp - srcTimestamp;

		// 当天（月份和日期一致才是）
		if (currentMonth == srcMonth && currentDateD == srcDateD) {
			// 时间相差60秒以内
			if (deltaTime < 60 * 1000) ret = '刚刚';
			// 否则当天其它时间段的，直接显示“时:分”的形式
			else ret = _formatDate(srcDate, 'hh:mm');
		}
		// 当年 && 当天之外的时间（即昨天及以前的时间）
		else {
			// 昨天（以“现在”的时候为基准-1天）
			var yesterdayDate = new Date();
			yesterdayDate.setDate(yesterdayDate.getDate() - 1);

			// 前天（以“现在”的时候为基准-2天）
			var beforeYesterdayDate = new Date();
			beforeYesterdayDate.setDate(beforeYesterdayDate.getDate() - 2);

			// 用目标日期的“月”和“天”跟上方计算出来的“昨天”进行比较，是最为准确的（如果用时间戳差值
			// 的形式，是不准确的，比如：现在时刻是2019年02月22日1:00、而srcDate是2019年02月21日23:00，
			// 这两者间只相差2小时，直接用“deltaTime/(3600 * 1000)” > 24小时来判断是否昨天，就完全是扯蛋的逻辑了）
			if (
				srcMonth == yesterdayDate.getMonth() + 1 &&
				srcDateD == yesterdayDate.getDate()
			)
				ret = '昨天' + timeExtraStr;
			// -1d
			// “前天”判断逻辑同上
			else if (
				srcMonth == beforeYesterdayDate.getMonth() + 1 &&
				srcDateD == beforeYesterdayDate.getDate()
			)
				ret = '前天' + timeExtraStr;
			// -2d
			else {
				// 跟当前时间相差的小时数
				var deltaHour = deltaTime / (3600 * 1000);

				// 如果小于或等 7*24小时就显示星期几
				if (deltaHour <= 7 * 24) {
					var weekday = new Array(7);
					weekday[0] = '星期日';
					weekday[1] = '星期一';
					weekday[2] = '星期二';
					weekday[3] = '星期三';
					weekday[4] = '星期四';
					weekday[5] = '星期五';
					weekday[6] = '星期六';

					// 取出当前是星期几
					var weedayDesc = weekday[srcDate.getDay()];
					ret = weedayDesc + timeExtraStr;
				}
				// 否则直接显示完整日期时间
				else ret = _formatDate(srcDate, 'yyyy/M/d') + timeExtraStr;
			}
		}
	}
	// 往年
	else {
		ret = _formatDate(srcDate, 'yyyy/M/d') + timeExtraStr;
	}

	return ret;
}
// ---------- 微信时间格式处理 end ----------------

// js 生成唯一标识
export function generateUUID() {
	let d = new Date().getTime();

	if (window.performance && typeof window.performance.now === 'function') {
		d += performance.now(); //use high-precision timer if available
	}

	let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
		c
	) {
		let r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);

		return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});

	return uuid;
}

// 复制功能
export function copyText(data) {
	const input = document.createElement('input');

	document.body.appendChild(input);
	input.setAttribute('value', data);
	input.select();

	if (document.execCommand('copy')) {
		document.execCommand('copy');
		// vm.$message.success('复制成功!');
	} else {
		vm.$alert(row.id, '浏览器不支持自动复制,请手动复制', {
			confirmButtonText: '关闭',
			callback: (action) => {},
		});
	}
	document.body.removeChild(input);
}

export function getFileName(url) {
	const result = url.match(/^.+\/(.+\.\w+)/i);
	return result ? result[1] : '';
}

export function getFileType(name) {
	const matchs = name.match(/[^\.]\w*$/i);
	return matchs[0];
}

export function isImgFile(name) {
	const imgs = ['jpg', 'png', 'jpeg', 'gif'];
	return imgs.indexOf(getFileType(name)) > -1;
}

// 平铺多维数组
export function flattenArray(arr) {
	const flatten = [].concat(...arr);

	return flatten.some((item) => Array.isArray(item))
		? flattenArray(flatten)
		: flatten;
}

// handler是我们需要包装的事件回调, delay是每次推迟执行的等待时间
export function debounce(handler, delay) {
	// 定时器
	let timer = null;

	// 将debounce处理结果当作函数返回
	return function() {
		// 保留调用时的this上下文
		let context = this;
		// 保留调用时传入的参数
		let args = arguments;

		// 每次事件被触发时，都去清除之前的旧定时器
		if (timer) {
			clearTimeout(timer);
		}
		// 设立新定时器
		timer = setTimeout(function() {
			handler.apply(context, args);
		}, delay);
	};
}

// 获取字符串长度, 区分 汉字 和 数字; 比如: 汉字长度为 1, 字母为 0.5
export function getByteLen(val, cl, el) {
	let len = 0;

	for (let i = 0; i < val.length; i++) {
		let length = val.charCodeAt(i);

		if (length >= 0 && length <= 128) {
			len += el;
		} else {
			len += cl;
		}
	}

	return len;
}

export function toThousands(num) {
	return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
}

// 是否为有效数字
export function isNumber(data) {
	return typeof data === 'number' && isFinite(data);
}

// 判断是否为 pc 端
export function isDesktop() {
	return device.desktop();
}

// 判断是否为微信
export function isWX() {
	const wx = navigator.userAgent.toLowerCase();
	return wx.match(/MicroMessenger/i) == 'micromessenger';
}
