<template>
	<div class="record-rule clearfix">
        <!--  @touchend="handleRecord"  @touchend="handleRule" -->
		<div class="record-btn pull-left" @click="handleRecord"></div>
		<div class="rule-btn pull-left" @click="handleRule"></div>
		<mask-dialog :show="maskRecordShow">
			<div class="record-list">
				<img
					class="record-list-img"
					:src="require('@imgs/new_year/bg_luck_list.png')"
					alt=""
				/>
                <!-- @touchend.stop="handleCloseRecord" -->
				<img
					class="record-list-close"
					:src="require('@imgs/new_year/btn_close.png')"
					alt=""
					@click="handleCloseRecord"
				/>
				<div class="record-list-content">
					<div class="record-list-h clearfix">
						<div class="pull-left record-list-item-t textcenter">
							时间
						</div>
						<div class="pull-left record-list-item-j textcenter">
							获得奖励
						</div>
					</div>
					<div
						class="clearfix record-list-data"
						v-for="(item, index) in recordList"
						:key="index"
					>
						<div class="pull-left record-list-item-t textcenter">
							{{
								formatDate(
									item.created * 1000,
									'MM-dd hh:mm:ss'
								)
							}}
						</div>
						<div class="pull-left record-list-item-j textcenter">
							{{ item.rewardInfo }}
						</div>
					</div>
				</div>
			</div>
		</mask-dialog>
		<mask-dialog :show="maskRuleShow">
			<div class="rule">
                <!-- @touchend.stop="handleCloseRule" -->
				<img
					class="rule-close"
					:src="require('@imgs/new_year/btn_close.png')"
					alt=""
					@click="handleCloseRule"
				/>
				<img
					class="rule-bg"
					:src="require('@imgs/new_year/bg_luck_rule.png')"
					alt=""
				/>
				<div class="rule-content">
					<p>. 根据玩家在活动期间内获得的疯狂幸运值进行排行;</p>
					<p>
						. 活动期间获得疯狂幸运值的方式包括
						<span class="rule-content-only"
							>充值获得, 抽奖活动</span
						>
						; 活动期间在疯狂游乐场平台任意一款游戏充值(不包括海盗来了, 呆呆打僵尸), 均可相应增加幸运值, 1元=10个幸运值;
					</p>
					<p>
						. 活动结束后幸运值在排行榜前20的玩家可获得对应的奖品;
					</p>
					<p>. 当疯狂幸运值相同, 则以达到幸运值的时间先后排名;</p>
					<p>. 疯狂幸运值可能存在延迟, 查看排行时请刷新活动页面;</p>
					<p>
						. 玩家充值时间以官方服务器时间为准, 请玩家不要卡点充值;
					</p>
					<p>. 本次活动为疯狂游乐场玩家专属活动;</p>
					<p>. 作弊行为一经发现, 一律取消活动资格;</p>
					<p>. 本活动最终解释权归疯狂游乐场官方运营团队所有.</p>
				</div>
			</div>
		</mask-dialog>
	</div>
</template>

<script>
import MaskDialog from '@components/maskDialog';
import $api from '@api/index';
import { formatDate } from '@utils/lib';

export default {
	data() {
		return {
			formatDate,
			maskRecordShow: false,
			maskRuleShow: false,
			recordList: [],
		};
	},

	components: { MaskDialog },

	methods: {
		handleRecord() {
			this.getLuckReward();
			this.maskRecordShow = true;
		},
		handleRule() {
			this.maskRuleShow = true;
		},
		handleCloseRecord(event) {
			this.maskRecordShow = false;
		},
		handleCloseRule() {
			this.maskRuleShow = false;
		},
		async getLuckReward() {
			const res = await $api.newyear_api.toGetLuckReward({
				tp: 'rank',
			});

			this.recordList = res.data || [];
		},
	},

	created() {
		// this.getLuckReward();
	},
};
</script>

<style scoped lang="scss">
.record-rule {
	.record-btn {
		width: rem(210);
		height: rem(50);
		background: url('~@imgs/new_year/btn_luckyrecord.png');
		background-repeat: no-repeat;
		background-size: cover;
	}
	.rule-btn {
		width: rem(170);
		height: rem(50);
		margin-left: rem(20);
		background: url('~@imgs/new_year/btn_rule.png');
		background-repeat: no-repeat;
		background-size: cover;
	}
	.rule {
		width: rem(550);
		height: rem(920);
		position: relative;
		.rule-close {
			width: rem(80);
			height: rem(80);
			position: absolute;
			top: 15px;
			right: -16px;
			z-index: 10000;
		}
		.rule-bg {
			width: 100%;
			height: 100%;
		}
		.rule-content {
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 rem(18);
			margin-top: rem(95);
			color: #791f1d;
			p {
				font-size: rem(22);
			}
			.rule-content-only {
				font-size: rem(30);
				font-weight: bold;
			}
		}
	}
	.record-list {
		width: rem(550);
		height: rem(520);
		position: relative;
		.record-list-img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.record-list-close {
			width: rem(60);
			height: rem(60);
			position: absolute;
			top: rem(50);
			right: -14px;
			z-index: 10000;
		}
		.record-list-content {
			margin: 0 rem(8);
			height: rem(435);
			background: transparent;
			position: absolute;
			margin-top: rem(70);
			overflow-y: scroll;
			overflow-x: hidden;
			text-align: center;
			.record-list-h {
				width: rem(535);
				font-size: rem(28);
				background: #c45b46;
				color: #ffeedd;
				font-weight: bold;
			}
			.record-list-item-t {
				width: rem(200);
				line-height: rem(85);
				// background: turquoise;
			}
			.record-list-item-j {
				width: rem(334);
				line-height: rem(85);
				// background: violet;
			}
			.record-list-item-o {
				width: rem(143);
				line-height: rem(85);
				// background: yellow;
			}
			.record-list-data {
				font-size: rem(20);
				border-bottom: 1px dashed #f49777;
				height: rem(85);
				font-weight: 600;
			}
			.receive-btn {
				width: rem(130);
				height: rem(56);
				display: inline-block;
				vertical-align: middle;
			}
			.receive-info {
				font-size: rem(28);
				font-weight: 700;
				color: #c45b46;
			}
		}
	}
}
</style>
