<template>
	<div class="text-container">
		<img
			class="text-container-img"
			:src="require('@imgs/new_year/img_roll.png')"
			alt=""
		/>
		<transition class="" name="slide" mode="out-in">
			<p class="text" :key="text.id">
				恭喜<span class="text-info">{{ text.val[0] }}</span>,
				获得了<span class="text-info">{{ text.val[1] }}</span>
			</p>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'TextScroll',

	props: {
		dataList: {
			type: Array,
			default() {
				return [];
			},
		},
	},

	data() {
		return {
			count: 0, // 当前索引
			intervalId: null, // 定时器ID
			playTime: 2000, // 定时器执行间隔
		};
	},

	computed: {
		text() {
			return {
				id: this.count,
				val: this.dataList[this.count],
			};
		},
	},

	created() {
		this.intervalId = setInterval(() => {
			// 定义定时器
			this.getText();
		}, this.playTime);
	},

	methods: {
		getText() {
			const len = this.dataList.length; // 获取数组长度
			if (len === 0) {
				return; // 当数组为空时，直接返回
			}
			if (this.count === len - 1) {
				// 当前为最后一个时
				this.count = 0; // 从第一个开始
			} else {
				this.count++; // 自增
			}
		},
	},

	destroyed() {
		clearInterval(this.intervalId); // 清除定时器
	},
};
</script>

<style scoped lang="scss">
.text-container {
	font-size: rem(22);
	font-weight: bold;
	color: #fff;
	overflow: hidden;
	text-align: center;
	position: relative;
	height: rem(45);
	.text-container-img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: rem(480);
		height: rem(45);
	}
	.text-info {
		color: #ffed28;
	}
}
.text {
	width: rem(410);
	text-align: left;
	margin: auto 0;
	// background: #fff;
	margin-left: rem(135);
	overflow: hidden; //超出的文本隐藏
	text-overflow: ellipsis; //溢出用省略号显示
	white-space: nowrap; //溢出不换行
}
.slide-enter-active,
.slide-leave-active {
	transition: all 1s;
}
.slide-enter {
	transform: translateY(8px);
}
.slide-leave-to {
	transform: translateY(-50px);
}
</style>
