import request from '@utils/request';
import bus from '@utils/bus.js';

const v1Url = (path) => {
	return `${window.HOST}/gc/${path}`;
};
const getActiveId = () => {
	let activeId = bus.query.activeId;

	if (!activeId || activeId == 'undefined') {
		activeId = '5f869be4abb4bd05c434135b';
	}

	return activeId;
};

const christmas_api = {
	// 1. 获取抽奖信息接口
	toGetLuckInfo() {
		const activeId = getActiveId();
		const params = { ...bus.query };
		const url = `${v1Url('actives/')}${activeId}`;

		return request.get(url, { params });
	},
	// 2. 获取抽奖次数
	toGetLuckCount() {
		const activeId = getActiveId();
		const params = { ...bus.query };
		const url = `${v1Url('actives-user/')}${activeId}`;

		return request.get(url, { params });
	},
	// 3. 开始抽奖
	toLuckRoll() {
		const activeId = getActiveId();
		const params = { ...bus.query };
		params.id = activeId;
		const url = v1Url('actives/roll');

		return request.post(url, {
			data: params,
			requestType: 'form',
		});
	},
	// 4. 中奖记录, 幸运值记录
	toGetLuckReward(data = {}) {
		const activeId = getActiveId();
		const params = { ...bus.query, ...data };
		const url = `${v1Url('actives-reward/')}${activeId}`;

		return request.get(url, { params });
	},
	// 5. 排行榜信息
	toGetRanking() {
		const activeId = getActiveId();
		const params = { ...bus.query };
		const url = `${v1Url('actives-ranking/')}${activeId}`;

		return request.get(url, { params });
	},
	// 6. 上传用户信息
	toSetUser(data) {
		const activeId = getActiveId();
		const params = { ...bus.query, ...data };
		params.id = activeId;
		const url = v1Url('active-winner-info');

		return request.post(url, {
			data: params,
			requestType: 'form',
		});
	},
};

export default christmas_api;
