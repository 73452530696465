<template>
	<div class="luck-info clearfix">
		<div class="luck-info-profile">
			<img class="profile-icon" :src="user.userImg || ''" alt="" />
			<div class="profile-sort">
				{{ !user.rank || user.rank == -1 ? '暂无排名' : user.rank || '' }}
			</div>
			<div class="profile-luck">{{ user.power || 0 }}</div>
			<div class="profile-len">
				与第一名的差距: {{ (firstUser.power || 0) - (user.power || 0) }}
			</div>
		</div>
		<div class="luck-info-top3">
			<img
				class="luck-info-top3-bg"
				:src="require('@imgs/new_year/img_rank123.png')"
				alt=""
			/>
			<div class="luck-info-top3-2">
				<img
					class="luck-info-top3-rab"
					:src="require('@imgs/new_year/icon_rank2.png')"
					alt=""
				/>
				<img
					class="luck-info-top3-2-icon"
					:src="secondUser.userImg || ''"
					alt=""
				/>
				<div class="luck-info-top3-2-name">
					{{ secondUser.nickname || '' }}
				</div>
				<div class="luck-info-top3-2-core">
					{{ secondUser.power || '' }}
				</div>
			</div>
			<div class="luck-info-top3-1">
				<img
					class="luck-info-top3-rab"
					:src="require('@imgs/new_year/icon_rank1.png')"
					alt=""
				/>
				<img
					class="luck-info-top3-1-icon"
					:src="firstUser.userImg || ''"
					alt=""
				/>
				<div class="luck-info-top3-1-name">
					{{ firstUser.nickname || '' }}
				</div>
				<div class="luck-info-top3-1-core">
					{{ firstUser.power || '' }}
				</div>
			</div>
			<div class="luck-info-top3-3">
				<img
					class="luck-info-top3-rab"
					:src="require('@imgs/new_year/icon_rank3.png')"
					alt=""
				/>
				<img
					class="luck-info-top3-3-icon"
					:src="thirdUser.userImg || ''"
					alt=""
				/>
				<div class="luck-info-top3-3-name">
					{{ thirdUser.nickname || '' }}
				</div>
				<div class="luck-info-top3-3-core">
					{{ thirdUser.power || '' }}
				</div>
			</div>
		</div>
		<div class="luck-info-top10">
			<img
				class="luck-info-top10-bg"
				:src="require('@imgs/new_year/img_rank.png')"
				alt=""
			/>
			<ul class="luck-info-top10-content">
				<div v-for="(item, index) in topList" :key="index">
					<li class="luck-info-top10-content-l clearfix" v-if="index > 2">
						<div class="pull-left top10-list-one">
							{{ index + 1 }}
						</div>
						<img
							class="pull-left top10-list-img"
							:src="item.userImg"
							alt=""
						/>
						<div class="pull-left top10-list-name">
							{{ item.nickname }}
						</div>
						<div class="pull-left top10-list-last">
							{{ item.power }}
						</div>
					</li>
				</div>
			</ul>
		</div>
	</div>
</template>

<script>
import $api from '@api/index';
import bus from '@utils/bus.js';

export default {
	data() {
		return {
			user: {},
			topList: [],
			firstUser: {},
			secondUser: {},
			thirdUser: {},
		};
	},

	methods: {
		async getRanking() {
			const res = await $api.newyear_api.toGetRanking();

			this.user = res.user || {};
			this.topList = res.data || [];

			if (this.topList.length >= 1) {
				this.firstUser = this.topList[0] || {};
			}
			if (this.topList.length >= 2) {
				this.secondUser = this.topList[1] || {};
			}
			if (this.topList.length >= 3) {
				this.thirdUser = this.topList[2] || {};
			}
		},
	},

	created() {
		this.getRanking();
		bus.$on('reload-data', this.getRanking);
	},
};
</script>

<style scoped lang="scss">
.luck-info {
	width: 100%;
	position: relative;
	height: rem(1020);
	margin-top: rem(40);
	// background: orange;
	// img_rank123.png
	.luck-info-top10 {
		// background: seagreen;
		// background: url('~@imgs/new_year/img_rank.png');
		// background-repeat: no-repeat;
		// background-size: cover;
		width: rem(580);
		height: rem(460);
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		// overflow-y: scroll;
		.luck-info-top10-bg {
			width: 100%;
			height: 100%;
		}
		.luck-info-top10-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			// background: springgreen;
			width: rem(520);
			height: rem(390);
			overflow-y: scroll;
			text-align: center;
			// padding: 0 rem(26);
			.luck-info-top10-content-l {
				background: #efca82;
				border-radius: rem(9);
				width: rem(500);
				height: rem(74);
				font-size: rem(22);
				font-weight: 700;
				margin-bottom: rem(8);
				margin-left: rem(11);
				.top10-list-one {
					width: rem(100);
					height: rem(74);
					line-height: rem(74);
					text-align: center;
					// background: cornflowerblue;
				}
				.top10-list-img {
					width: rem(56);
					height: rem(56);
					border: 2px solid #fff;
					border-radius: 50%;
					margin-top: rem(6);
				}
				.top10-list-name {
					height: rem(74);
					line-height: rem(74);
					width: rem(120);
					margin-left: rem(14);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: left;
				}
				.top10-list-last {
					height: rem(74);
					line-height: rem(74);
					word-break: break-all;
					width: rem(185);
					text-align: right;
				}
			}
		}
	}
	.luck-info-top3 {
		// background: url('~@imgs/new_year/img_rank123.png');
		// background-repeat: no-repeat;
		// background-size: cover;
		width: rem(510);
		height: rem(275);
		// background: chartreuse;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		margin-top: rem(260);
		.luck-info-top3-bg {
			width: 100%;
			height: 100%;
		}
		.luck-info-top3-rab {
			width: rem(72);
			height: rem(52);
			position: absolute;
			top: rem(-40);
			left: 50%;
			transform: translateX(-50%);
			z-index: 20000;
		}
		.luck-info-top3-3 {
			// background: firebrick;
			width: rem(160);
			height: rem(175);
			position: absolute;
			bottom: 0;
			right: 0;
			.luck-info-top3-3-icon {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				width: rem(72);
				height: rem(72);
				background: cornflowerblue;
				border-radius: 50%;
				// margin-left: rem(40);
				// margin-top: rem(-20);
				border: 2px solid #fff;
			}
			.luck-info-top3-3-name {
				position: absolute;
				left: 50%;
				transform: translate(-50%);
				font-size: rem(23);
				font-weight: bold;
				color: #fff;
				width: rem(150);
				text-align: center;
				padding: 0 rem(15);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				bottom: rem(60);
			}
			.luck-info-top3-3-core {
				position: absolute;
				left: 50%;
				top: rem(120);
				transform: translate(-50%);
				font-size: rem(25);
				font-weight: 800;
				color: #fff;
				width: rem(150);
				text-align: center;
				word-break: break-all;
			}
		}
		.luck-info-top3-1 {
			// background: chocolate;
			width: rem(190);
			height: rem(220);
			position: absolute;
			bottom: 0;
			left: rem(160);
			.luck-info-top3-1-icon {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				width: rem(72);
				height: rem(72);
				border-radius: 50%;
				border: 2px solid #fff;
			}
			.luck-info-top3-1-name {
				position: absolute;
				left: 50%;
				transform: translate(-50%);
				font-size: rem(23);
				font-weight: bold;
				color: #fff;
				width: rem(150);
				text-align: center;
				padding: 0 rem(15);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				bottom: rem(100);
			}
			.luck-info-top3-1-core {
				position: absolute;
				left: 50%;
				top: rem(120);
				transform: translate(-50%);
				font-size: rem(25);
				font-weight: 800;
				color: #fff;
				width: rem(150);
				text-align: center;
				word-break: break-all;
			}
		}
		.luck-info-top3-2 {
			width: rem(160);
			height: rem(175);
			// background: #ccc;
			position: absolute;
			bottom: 0;
			left: 0;
			.luck-info-top3-2-icon {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				width: rem(72);
				height: rem(72);
				background: cornflowerblue;
				border-radius: 50%;
				// margin-left: rem(40);
				// margin-top: rem(-20);
				border: 2px solid #fff;
			}
			.luck-info-top3-2-name {
				position: absolute;
				left: 50%;
				transform: translate(-50%);
				font-size: rem(23);
				font-weight: bold;
				color: #fff;
				width: rem(150);
				text-align: center;
				padding: 0 rem(15);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				bottom: rem(60);
			}
			.luck-info-top3-2-core {
				position: absolute;
				left: 50%;
				top: rem(120);
				transform: translate(-50%);
				font-size: rem(25);
				font-weight: 800;
				color: #fff;
				width: rem(150);
				text-align: center;
				word-break: break-all;
			}
		}
	}
	.luck-info-profile {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: rem(600);
		height: rem(256);
		background: url('~@imgs/new_year/img_me.png');
		background-repeat: no-repeat;
		background-size: cover;
		.profile-icon {
			position: absolute;
			left: rem(144);
			top: rem(36);
			width: rem(49);
			height: rem(49);
			border-radius: 50%;
			border: 2px solid #fff;
			background: mediumaquamarine;
		}
		.profile-sort {
			position: absolute;
			right: rem(87);
			top: rem(45);
			height: rem(50);
			width: rem(200);
			background: #b8332c;
			font-size: rem(35);
			font-weight: 800;
			color: #fff;
		}
		.profile-luck {
			position: absolute;
			right: rem(40);
			top: rem(124);
			height: rem(50);
			width: rem(205);
			background: #b8332c;
			font-size: rem(35);
			font-weight: 800;
			color: #fff;
		}
		.profile-len {
			padding-top: rem(10);
			text-align: right;
			width: rem(540);
			// height: rem(40);
			position: absolute;
			bottom: rem(30);
			right: rem(30);
			// F9B683 FACBA7
			background: linear-gradient(#f9b683, #facba7);
			color: #b8332c;
			font-size: rem(22);
			font-weight: bold;
		}
	}
}
</style>
