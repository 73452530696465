<template>
	<div class="lottery-result">
		<mask-dialog :show="maskShow">
			<div class="record-list">
				<img
					class="record-list-img"
					:src="require('@imgs/new_year/bg_kong.png')"
					alt=""
				/>
				<img
					class="record-list-close"
					:src="require('@imgs/new_year/btn_close.png')"
					alt=""
					@click="handleCloseRecord"
				/>
				<!-- 1. 没有次数的弹窗 开始 -->
				<div class="box-no-count" v-if="isCount">
					<img
						class="box-no-count-img"
						:src="require('@imgs/new_year/no_count.png')"
						alt=""
					/>
					<div class="box-no-count-content">
						<h4>一. 累计登录奖励</h4>
						<!-- get_count_one.png -->
						<img
							class="box-get-count-img"
							:src="require('@imgs/new_year/get_count_one.png')"
							alt=""
						/>
						<h4>二. 活动任务奖励</h4>
						<div class="clearfix task">
							<h6 class="pull-left">
								<span v-if="bus.query.isMiniProgram !== 'true'"
									>任务一:</span
								>
								任意游戏充值任意金额获得一次({{ payCount }}/5)
							</h6>
							<div class="get_btn pull-left" @click="handlePay">
								<img
									:src="
										require('@imgs/new_year/get_btnbg.png')
									"
									alt=""
								/>
								<span>去充值</span>
							</div>
						</div>
						<div
							class="clearfix task"
							v-if="bus.query.isMiniProgram !== 'true'"
						>
							<h6 class="pull-left">
								任务二: 关注公众号获得一次
							</h6>
							<span
								class="receive-info pull-left"
								v-if="isSubscribe"
								>已领取</span
							>
							<div
								class="pull-left get_btn"
								v-else
								@click="handleGet"
							>
								<img
									:src="
										require('@imgs/new_year/get_btnbg.png')
									"
									alt=""
								/>
								<span>领取</span>
							</div>
						</div>
					</div>

					<!-- <div class="box-content-title">充值可获得抽奖机会</div> -->
				</div>
				<!-- 1. 没有次数的弹窗 结束 -->

				<!-- 2. 获得积分的弹窗 开始 -->
				<div v-if="isIntegral" class="integral_box">
					<!-- congratulation.png -->
					<img
						class="integral_title"
						:src="require('@imgs/new_year/congratulation.png')"
						alt=""
					/>
					<div class="integral_count">
						<!-- 获得平台积分{{ luckInfo.integral || 0 }}分已发放 -->
						{{ luckInfo.detail || '' }}
					</div>
					<div>
						<img
							class="integral_count_img"
							:src="
								luckInfo.smallImg ||
									require('@imgs/new_year/100.png')
							"
							alt=""
						/>
					</div>
					<div>
						<img
							@click="handleCloseRecord"
							class="integral_count_btn"
							:src="require('@imgs/new_year/submitbtn.png')"
							alt=""
						/>
					</div>
				</div>
				<!-- 2. 获得积分的弹窗 结束 -->

				<!-- 3. 电话卡的弹窗 开始 -->
				<div v-if="isPhoneCard" class="phone_box">
					<img
						class="integral_title"
						:src="require('@imgs/new_year/congratulation.png')"
						alt=""
					/>
					<div class="phone_info">{{ luckInfo.detail || '' }}</div>
					<img
						class="phone_card_img"
						:src="luckInfo.smallImg || ''"
						alt=""
					/>
					<div class="phone_other">请到中奖记录处查看兑换码吧</div>
					<div class="phone_tip">
						提示: 实际发放可能为多张100元移动话费卡
					</div>
					<div>
						<img
							@click="handleCloseRecord"
							class="integral_count_btn"
							:src="require('@imgs/new_year/submitbtn.png')"
							alt=""
						/>
					</div>
				</div>
				<!-- 3. 电话卡的弹窗 结束 -->

				<!-- 4. 游戏礼包的弹窗 开始 -->
				<div v-if="isGameGift && !isGiftSuccess" class="gift_box">
					<img
						class="integral_title"
						:src="require('@imgs/new_year/congratulation.png')"
						alt=""
					/>
					<div class="phone_info">
						{{ luckInfo.detail || luckInfo.rewardInfo || '测试' }}
					</div>
					<img
						class="gift_box_line"
						:src="require('@imgs/new_year/gift_line.png')"
						alt=""
					/>
					<ul class="games clearfix">
						<li
							class="pull-left games_item"
							v-for="(item, index) in gameGifts"
							:key="index"
							@click="handleCheck(item)"
						>
							<img
								class="games_icon"
								:src="item.gameIcon"
								alt=""
							/>
							<div class="games_name">{{ item.gameName }}</div>
							<img
								class="games_round"
								:src="require('@imgs/new_year/round.png')"
								alt=""
							/>
							<img
								class="games_checked"
								:src="require('@imgs/new_year/checked.png')"
								alt=""
								v-if="activeItem.gameId == item.gameId"
							/>
						</li>
					</ul>
					<div>
						<img
							@click="handleSetGift"
							class="integral_count_btn"
							:src="require('@imgs/new_year/submitbtn.png')"
							alt=""
						/>
					</div>
				</div>
				<!-- 4. 游戏礼包的弹窗 结束 -->

				<!-- 5. 礼包领取成功的弹窗 开始 -->
				<div v-if="isGiftSuccess" class="gift_success">
					<img
						class="gift_success_img"
						:src="require('@imgs/new_year/get_success.png')"
						alt=""
					/>
					<div class="gift_success_top">
						{{
							giftInfo.gameName ||
								luckInfo.gameName ||
								'测试游戏'
						}}专属礼包兑换码
					</div>
					<div class="gift_success_code">
						{{ giftInfo.giftCode || luckInfo.giftCode || '' }}
					</div>
					<div class="gift_success_bottom">
						复制兑换码, 去游戏中使用
					</div>
					<img
						@click="handleCopy"
						class="gift_success_copybtn"
						:src="require('@imgs/new_year/copy.png')"
						alt=""
					/>
				</div>
				<!-- 5. 礼包领取成功的弹窗 结束 -->

				<!-- 6. 电话卡成功的弹窗 开始 -->
				<div v-if="isPhoneSuccess" class="phone_success">
					<img
						class="phone_success_img"
						:src="require('@imgs/new_year/get_success_title.png')"
						alt=""
					/>
					<div class="phone_success_top">
						{{ luckInfo.rewardInfo || '100元移动电话卡' }}
					</div>
					<div class="phone_success_center">
						序号: {{ luckInfo.giftCode || '' }}
					</div>
					<div class="phone_success_top">
						操作提示: 拨打10086充值热线, 根据引导序列号即可充值成功
					</div>
					<img
						@click="handleCopy"
						class="gift_success_copybtn"
						:src="require('@imgs/new_year/copy.png')"
						alt=""
					/>
				</div>
				<!-- 6. 电话卡成功的弹窗 结束 -->

				<!-- <div class="box-content" v-else>
					<div>
						<div
							v-if="luckInfo.type"
							class="box-content-title box-content-link"
						>
							领奖联系方式
						</div>
						<div v-else>
							<div class="box-content-title">恭喜您</div>
							<div class="box-content-title">
								{{
									(luckInfo.detail || '').includes('获得')
										? luckInfo.detail || ''
										: `获得${luckInfo.detail || ''}`
								}}
							</div>
						</div>
					</div>
					<div
						class="box-content-o"
						v-if="bus.hasWinnerInfo && luckInfo.tp == 0"
					>
						您已填写联系方式, 客服会在7个工作日内联系您发放,
						如您想修改联系方式, 请添加客服微信 ddcz04 修改
					</div>
					<div
						v-if="!bus.hasWinnerInfo && luckInfo.tp == 0"
						class="box-content-form"
					>
						<div class="box-content-o">
							请输入您的联系方式, 客服会在7个工作日联系您发放
						</div>
						<div class="login_item clearfix login_item_first">
							<span class="box-content-o box-content-l pull-left"
								>手机号:
							</span>
							<input
								type="text"
								class="pull-left"
								v-model="userInfo.mobile"
							/>
						</div>
						<div class="login_item clearfix">
							<span class="box-content-o box-content-l pull-left"
								>微信号:
							</span>
							<input
								type="text"
								class="pull-left"
								v-model="userInfo.wxNo"
							/>
						</div>
						<div class="box-content-submit">
							<img
								@click="handleSetUser"
								:src="require('@imgs/new_year/btn_ok.png')"
								alt=""
							/>
						</div>
					</div>
				</div> -->
			</div>
		</mask-dialog>
		<mask-dialog :show="publicShow">
			<div class="public_box">
				<img
					class="public_show_img"
					:src="
						$root.env == 'prod'
							? require('@imgs/new_year/public.png')
							: require('@imgs/new_year/plulic_test_1.png')
					"
					alt=""
				/>
				<img
					class="public_close"
					:src="require('@imgs/new_year/btn_close.png')"
					alt=""
					@click="handleClosePulic"
				/>
			</div>
		</mask-dialog>
	</div>
</template>

<script>
import MaskDialog from '@components/maskDialog';
import bus from '@utils/bus.js';
import { copyText } from '@utils/lib.js';
import $api from '@api/index';

export default {
	props: [
		'maskShow',
		'luckInfo',
		'isCount',
		'isSubscribe',
		'payCount',
		'isIntegral',
		'isPhoneCard',
		'isGameGift',
		'gameGifts',
		'isPhoneSuccess',
	],

	data() {
		return {
			bus,
			userInfo: {},
			publicShow: false,
			activeItem: {},
			isGiftSuccess: false,
			giftInfo: {},
		};
	},

	components: { MaskDialog },

	methods: {
		async setUser() {
			const mobile = this.userInfo.mobile || '';
			const wxNo = this.userInfo.wxNo || '';

			if (!/^1[0123456789]\d{9}$/.test(mobile)) {
				this.$toast.top('请输入有效手机号!');
				return;
			}

			if (!wxNo) {
				this.$toast.top('请输入微信号!');
				return;
			}

			const params = { ...this.userInfo };
			const res = await $api.newyear_api.toSetUser(params);

			if (res.code == 200) {
				this.$emit('close');
				bus.hasWinnerInfo = true;
				this.$toast.top('提交信息成功!');
			}
		},
		handleSetUser() {
			this.setUser();
		},
		handleCloseRecord() {
			this.activeItem = {};
			this.giftInfo = {};
			this.isGiftSuccess = false;
			this.$emit('close');
		},
		handlePay() {
			console.log('pay');
			if (window.parent) {
				window.parent.postMessage({ msgType: 'back', data: {} }, '*');
			}
		},
		async handleGet() {
			const res = $api.newyear_api.toSubscribe();

			if (res.isReceive) {
				// 领取成功
				bus.$emit('luck-count');
				this.$toast.top('领取成功!');
			} else {
				this.publicShow = true;
			}
		},
		async handleClosePulic() {
			await this.handleGet();
			bus.$emit('luck-count');
			this.publicShow = false;
		},
		handleCheck(data) {
			this.activeItem = data;
		},
		async handleSetGift() {
			if (!this.activeItem.gameId) {
				this.$toast.top('请选择游戏!');
				return;
			}
			const params = {
				id: this.luckInfo.id,
				batch: this.activeItem.batch,
				gameId: this.activeItem.gameId,
			};
			const res = await $api.newyear_api.toSetGift(params);

			if (!res) {
				// this.$toast.top('礼包码已经被领完了!');
				return;
			}

			this.isGiftSuccess = true;
			this.giftInfo = res.data || {};
			bus.$emit('record-list');
		},
		handleCopy() {
			const giftCode =
				this.giftInfo.giftCode ||
				this.luckInfo.giftCode ||
				'暂无礼包码';

			copyText(giftCode);
			this.$toast.top('复制成功!');
		},
		setGiftSuccess(data) {
			console.log('=== set gift ===', data);
			this.isGiftSuccess = data;
		},
	},

	created() {
		bus.$on('gift-success', this.setGiftSuccess);
	},
};
</script>

<style scoped lang="scss">
.lottery-result {
	position: relative;
	.public_box {
		position: relative;
		.public_show_img {
			width: rem(550);
			margin-top: rem(20);
		}
		.public_close {
			width: rem(50);
			position: absolute;
			right: rem(-20);
			top: 0;
		}
	}

	.record-list {
		width: rem(570);
		height: rem(700);
		position: relative;
		.record-list-img {
			position: absolute;
			top: rem(20);
			left: 0;
			width: 100%;
			height: 100%;
		}
		.record-list-close {
			width: rem(60);
			height: rem(60);
			position: absolute;
			top: rem(0);
			right: -14px;
			z-index: 10000;
		}
	}
	.box-content {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		margin-top: rem(20);
		.login_item {
			// border-bottom: 1px solid #ccc;
			height: rem(60);
			width: rem(400);
			margin-left: rem(50);

			input {
				width: rem(300);
				outline: none;
				height: rem(60);
				padding-left: rem(10);
				border: none;
				margin-left: rem(8);
				font-size: rem(25);
			}
			input::-webkit-input-placeholder {
				font-size: rem(25);
				color: #cccccc;
			}
		}
		.login_item_first {
			margin-bottom: rem(8);
			margin-top: rem(20);
		}
		.box-content-title {
			font-size: rem(30);
			width: 100%;
			width: rem(500);
			font-weight: bold;
			color: #772f29;
			text-align: center;
		}
		.box-content-link {
			margin-bottom: rem(12);
		}
		.box-content-o {
			font-size: rem(22);
			color: #a44f39;
		}
		.box-content-l {
			height: rem(60);
			display: inline-block;
			line-height: rem(60);
		}
		// .box-content-no-count {
		// 	// width: 80%;

		// }
	}
	.box-content-form {
		margin: rem(20) 0;
	}
	.box-content-submit {
		height: rem(60);
		position: relative;
		margin-top: rem(20);
		img {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			width: rem(180);
			height: rem(60);
		}
	}
	// 没有次数
	.box-no-count {
		position: absolute;
		left: 50%;
		top: 0%;
		transform: translateX(-50%);
		margin-top: rem(100);
		font-size: rem(30);
		// background: red;
		width: 95%;
		.box-no-count-img {
			width: 80%;
		}
		.box-no-count-content {
			h4 {
				margin-left: rem(-290);
				margin-top: rem(28);
				margin-bottom: rem(20);
			}
			.box-get-count-img {
				width: 70%;
			}
			.task {
				margin-left: rem(12);
				margin-top: rem(5);
				h6 {
					margin: rem(8) 0;
				}
				.receive-info {
					font-size: rem(28);
					font-weight: 700;
					color: #c45b46;
					margin-left: rem(8);
				}
			}
			.get_btn {
				font-size: rem(20);
				position: relative;
				margin-left: rem(8);
				color: #81360e;
				font-weight: bold;
				img {
					position: absolute;
					width: rem(80);
					height: rem(40);
				}
				span {
					position: absolute;
					left: 0;
					top: rem(4);
					z-index: 100;
					width: rem(80);
					height: rem(40);
				}
			}
		}
	}
	.integral_title {
		display: block;
		width: 40%;
		margin: 0 auto;
	}
	.integral_count_btn {
		display: block;
		margin: 0 auto;
		width: 50%;
	}
	// 积分
	.integral_box {
		position: absolute;
		left: 50%;
		top: 0%;
		transform: translateX(-50%);
		margin-top: rem(100);
		font-size: rem(30);
		width: 95%;
		.integral_count {
			margin: rem(15) 0;
			font-size: rem(30);
			font-weight: bold;
			color: #772f29;
		}
		.integral_count_img {
			margin: rem(15) 0;
		}
	}
	.phone_info {
		background: #b58038;
		width: rem(300);
		margin: rem(8) auto;
		color: #fff;
		border-radius: rem(5);
		text-align: center;
	}
	// 电话卡
	.phone_box {
		position: absolute;
		left: 50%;
		top: 0%;
		transform: translateX(-50%);
		margin-top: rem(100);
		font-size: rem(30);
		width: 95%;
		.phone_card_img {
			width: 35%;
		}
		.phone_other {
			font-size: rem(30);
			color: #57636d;
			font-weight: bold;
			text-align: center;
		}
		.phone_tip {
			text-align: center;
		}
	}
	.gift_box {
		position: absolute;
		left: 50%;
		top: 0%;
		transform: translateX(-50%);
		margin-top: rem(100);
		font-size: rem(30);
		width: 95%;
		.gift_box_line {
			width: rem(500);
		}
		.games {
			width: 100%;
			height: rem(320);
			overflow-x: hidden;
			overflow-y: auto;
			margin-bottom: rem(8);
			padding-left: rem(8);
			.games_item {
				width: rem(120);
				height: rem(180);
				margin-right: rem(14.8);
				margin-bottom: rem(14.8);
				position: relative;
				.games_icon {
					width: 100%;
					height: rem(120);
				}
				.games_name {
					width: 100%;
					position: absolute;
					bottom: rem(30);
					left: 50%;
					transform: translateX(-50%);
					font-size: rem(12);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-align: center;
				}
				.games_round {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					width: rem(28);
					height: rem(28);
				}
				.games_checked {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					width: rem(30);
					height: rem(30);
				}
			}
		}
	}
	.gift_success {
		position: absolute;
		left: 50%;
		top: 0%;
		transform: translateX(-50%);
		margin-top: rem(100);
		font-size: rem(30);
		width: 95%;
		.gift_success_img {
			width: 45%;
			margin-left: rem(190);
			display: block;
		}
		.gift_success_top {
			font-size: rem(40);
			font-weight: bold;
			color: #7a2f1e;
			text-align: center;
		}
		.gift_success_bottom {
			font-weight: bold;
			color: #7a2f1e;
			margin: rem(20) 0;
			text-align: center;
		}
		.gift_success_code {
			background: #b58038;
			width: rem(500);
			margin: rem(20) auto;
			color: #fff;
			border-radius: rem(5);
			text-align: center;
		}
	}
	.gift_success_copybtn {
		width: 35%;
		margin: 0 auto;
		display: block;
	}
	.phone_success {
		position: absolute;
		left: 50%;
		top: 0%;
		transform: translateX(-50%);
		margin-top: rem(100);
		font-size: rem(30);
		width: 95%;
		color: #7a2f1e;
		.phone_success_img {
			width: 55%;
			display: block;
			margin: 0 auto;
		}
		.phone_success_top {
			text-align: center;
			margin: rem(20) 0;
			// background: red;
			font-size: rem(30);
			font-weight: bold;
		}
		.phone_success_center {
			margin: rem(20) 0;
			text-align: center;
			font-size: rem(45);
			font-weight: bold;
		}
	}
}
</style>
