<template>
	<div class="luck-list">
		<img
			class="luck-list-img"
			:src="require('@imgs/new_year/table_rank.png')"
			alt=""
		/>
        <active-time class="active-time"></active-time>
        <img class="img-prize" :src="require('@imgs/new_year/img_prize.png')" alt="">
	</div>
</template>

<script>
import ActiveTime from './active_time';

export default {
	data() {
		return {};
	},

	components: { ActiveTime },
};
</script>

<style scoped lang="scss">
.luck-list {
	text-align: center;
	.luck-list-img {
		// margin-top: rem(10);
		height: rem(50);
		width: rem(270);
	}
    .active-time {
        margin-top: rem(-45);
    }
    .img-prize {
        width: rem(560);
        height: rem(520);
        margin-top: rem(10);
        // margin: rem(10) rem(20);
    }
}
</style>
